import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Images/logo.webp";
import secureLocalStorage from "react-secure-storage";

export default function CertiDetail() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  var cert_details = secureLocalStorage.getItem("cert_details");
  var RedemObject = JSON.parse(cert_details);
  const isVoucher = secureLocalStorage.getItem("certDetail");

  useEffect(() => {
    if (isVoucher !== true || isVoucher === undefined || isVoucher === null) {
      navigate("/", { replace: true });
      setTimeout(() => {
        setShow(true);
      }, 500);
    } else {
      setShow(true);
      secureLocalStorage.setItem("certDetail", false);
    }
  }, []);

  return show ? (
    <div className="certi-detail">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="logo mb-0">
              {" "}
              <img src={Logo} alt="logo" className="img-fluid" />{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="outer-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="info-sec">
                <h2 className="cert-head">Certificate Details</h2>
                <h4 className="underline">Contact Information</h4>

                <form
                  className="form-outer register clearfix"
                  data-hs-cf-bound="true"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="alignment">
                        <label>
                          <strong>First Name</strong>
                        </label>
                        <br />
                        <span>{RedemObject?.fname}</span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="alignment">
                        <label>
                          <strong>Last Name</strong>
                        </label>
                        <br />
                        <span>{RedemObject?.lname}</span>
                      </div>
                    </div>
                    <div className="clearfix"></div>

                    <div className="col-md-6">
                      <div className="alignment">
                        <label>
                          <strong>Address</strong>
                        </label>
                        <br />
                        <span>{RedemObject?.address}</span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="alignment">
                        <label>
                          <strong>Address2</strong>
                        </label>
                        <br />
                        <span>{RedemObject?.address2}</span>
                      </div>
                    </div>

                    <div className="clearfix"></div>
                    <div className="col-md-6">
                      <div className="alignment">
                        <label>
                          <strong>City</strong>
                        </label>
                        <br />
                        <span>{RedemObject?.city}</span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="alignment">
                        <label>
                          <strong>State</strong>
                        </label>
                        <br />
                        <span>{RedemObject?.state}</span>
                      </div>
                    </div>

                    <div className="clearfix"></div>
                    <div className="col-md-6">
                      <div className="alignment">
                        <label>
                          <strong>Zip Code</strong>
                        </label>
                        <br />
                        <span>{RedemObject?.zip}</span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="alignment">
                        <label>
                          <strong>Phone No</strong>
                        </label>
                        <br />
                        <span>{RedemObject?.phone}</span>
                      </div>
                    </div>

                    <div className="clearfix"></div>
                    <div className="col-md-6">
                      <div className="alignment">
                        <label>
                          <strong>Email</strong>
                        </label>
                        <br />
                        <span>{RedemObject?.email}</span>
                      </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <h2 className="underline">Select Gift with Purchase</h2>

                    <div className="check-boexs cen">
                      <div className="reward_cert">
                        <span className="insdex">
                          <span>{RedemObject?.cardName}</span>
                        </span>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_Link +
                            RedemObject?.cardImage
                          }
                          className="imgcolor img-responsive"
                        />
                      </div>
                    </div>
                  </div>
                  <Link to="/" className="btn btn-md btn-primary">
                    Go Back
                  </Link>
                </form>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
        <footer className="login-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                © Copyright {new Date().getFullYear()} All rights reserved.{" "}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  ) : (
    <div className="white-screen"></div>
  );
}
