import React, { useLayoutEffect } from "react";
import Logo from "../../../Images/logo.webp";
import LogoWhite from "../../../Images/brand-log.png";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux";
import { Link, useNavigate } from "react-router-dom";

export default function HeaderUser() {
  const navigate = useNavigate();

  const state = useSelector((state) => state.stateVals);
  const { certificate, expiration, cartCount } = state;
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const logOut = async (e) => {
    e.preventDefault();
    await userActions.userLogOut("userLogOut");
    navigate("/", { replace: true });
  };

  useLayoutEffect(() => {
    if (certificate) {
      userActions.cartCounter(certificate, "cartCounter");
      setTimeout(async () => {
        await userActions.userLogOut("userLogOut");
        navigate("/", { replace: true });
      }, 14400000);
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <>
      <div className="header-outer">
        <div className="container">
          <nav className="navbar navbar-expand-lg  p-0">
            <div className="container-fluid p-0">
              <Link className="navbar-brand" to="/viewreward">
                <img src={Logo} alt="logo" className="img-fluid " />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to="/viewreward"
                    >
                      Home
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      My Account
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/cart">
                          Shopping Cart
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Information
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/help">
                          Help
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/TOS">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/contact-us">
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="/" className="nav-link" onClick={logOut}>
                      Exit
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div className="breadcrumb-wrap d-none d-sm-block d-md-block d-lg-block">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="user-info">
                  <div className="row">
                    <div className="col-md-4 col-sm-4 drop-xs-12 col-xs-12">
                      <span className="welcome">
                        Certificate No: {certificate}
                      </span>
                    </div>

                    <div className="col-md-4 col-sm-4 drop-xs-12 col-xs-12">
                      <span className="points expiry-date">
                        Expiration: {expiration}
                      </span>
                    </div>
                    <div className="col-md-4 col-sm-4 drop-xs-12 col-xs-12">
                      <Link id="item" to="/cart">
                        <span className="cart">
                          Cart: <span id="ctotal">{cartCount}</span>&nbsp;Items
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="outer-bg">
        <div className="container">
          <img src={LogoWhite} className="img-fluid banner-img" alt="logo" />
        </div>
      </div>

      <div className="ul-outer d-none d-md-block ">
        <ul className="ul-inner">
          <li>
            <Link id="item" to="/cart">
              Go to Cart (<span id="ctotal2">0</span>){" "}
              <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
            </Link>
          </li>
        </ul>
      </div>
      <div className="ul-outer d-block d-md-none">
        <ul className="ul-inner">
          <li>
            <Link id="item" to="/cart">
              (<span id="ctotal3">{cartCount}</span>){" "}
              <i className="fa fa-shopping-cart" aria-hidden="true"></i>
            </Link>
          </li>

          <li>
            <button data-bs-toggle="modal" data-bs-target="#userinfo">
              <i className="fa fa-user" aria-hidden="true"></i>
            </button>
          </li>
          <li>
            <button onClick={logOut}>
              <i className="fa fa-sign-out" aria-hidden="true"></i>
            </button>
          </li>
        </ul>
      </div>

      <div
        className="modal fade used-model"
        id="userinfo"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="userinfoLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                User Information
              </h4>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <i className="fa fa-user circle" aria-hidden="true"></i>
                  <h6>Certificate No: </h6>

                  <p>{certificate}</p>
                </div>
                {/* <div className="col-md-12">
                  <i className="fa fa-star circle" aria-hidden="true"></i>
                  <h6>Points:</h6>
                  <p>50</p>
                </div> */}
                <div className="col-md-12">
                  <i className="fa fa-calendar circle" aria-hidden="true"></i>
                  <h6>Expiration:</h6>
                  <p>{expiration}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
