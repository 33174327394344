import UserListService from "../../services/user-list.service";

export const logIn = (states, type) => {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: states,
    });
  };
};

export const logOut = (type) => {
  return (dispatch) => {
    dispatch({
      type: type,
    });
  };
};

export const storeVoucher = (states, type) => {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: states,
    });
  };
};

export const userLogOut = (type) => {
  return (dispatch) => {
    dispatch({
      type: type,
    });
  };
};

export const cartCounter = (states, type) => async (dispatch) => {
  try {
    const response = await UserListService.getCartCounter(states);
    const cartCount = response?.data?.response;

    dispatch({
      type: type,
      payload: cartCount,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: type,
      payload: 0,
    });
  }
};
