import secureLocalStorage from "react-secure-storage";

// secureLocalStorage.setItem("acessTokens", JSON.stringify(acessTokens));

let retrievedObject = JSON.parse(secureLocalStorage.getItem("acessTokens"));
let voucherData = JSON.parse(secureLocalStorage.getItem("voucherInfo"));

const adminAccessToken = retrievedObject?.adminAccessToken;
const admin_id = retrievedObject?.admin_id;
const user_type = retrievedObject?.user_type;

const certificate = voucherData?.certificate;
const expiration = voucherData?.expiration;
const cartCount = voucherData?.cartCount;

if (!retrievedObject) {
  retrievedObject = {};
}
if (!voucherData) {
  voucherData = {};
}

const initialState = {
  adminAccessToken: adminAccessToken ? adminAccessToken : null,
  admin_id: admin_id ? admin_id : null,
  user_type: user_type ? user_type : null,
  certificate: certificate ? certificate : null,
  expiration: expiration ? expiration : null,
  cartCount: cartCount ? cartCount : 0,
};

const reducer = (state = initialState, action) => {
  if (action.type === "adminLogin") {
    retrievedObject.adminAccessToken = action.payload.adminAccessToken;
    retrievedObject.admin_id = action.payload.admin_id;
    retrievedObject.user_type = action.payload.user_type;
    secureLocalStorage.setItem("acessTokens", JSON.stringify(retrievedObject));

    return {
      ...state,
      adminAccessToken: action.payload.adminAccessToken,
      admin_id: action.payload.admin_id,
      user_type: action.payload.user_type,
    };
  } else if (action.type === "voucherSave") {
    voucherData.certificate = action.payload.certificate;
    voucherData.expiration = action.payload.expiration;

    secureLocalStorage.setItem("voucherInfo", JSON.stringify(voucherData));

    return {
      ...state,
      certificate: action.payload.certificate,
      expiration: action.payload.expiration,
    };
  } else if (action.type === "adminLogOut") {
    delete retrievedObject?.admin_id;
    delete retrievedObject?.user_type;
    delete retrievedObject?.adminAccessToken;
    if (Object.keys(retrievedObject).length !== 0) {
      secureLocalStorage.setItem(
        "acessTokens",
        JSON.stringify(retrievedObject)
      );
    } else {
      secureLocalStorage.removeItem("acessTokens");
    }

    return {
      ...state,
      adminAccessToken: null,
      admin_id: null,
      user_type: null,
    };
  } else if (action.type === "userLogOut") {
    delete voucherData?.certificate;
    delete voucherData?.expiration;
    delete voucherData?.cartCount;

    if (Object.keys(voucherData).length !== 0) {
      secureLocalStorage.setItem(
        "voucherInfo",
        JSON.stringify(retrievedObject)
      );
    } else {
      secureLocalStorage.removeItem("voucherInfo");
    }

    return {
      ...state,
      certificate: null,
      expiration: null,
      cartCount: 0,
    };
  } else if (action.type === "cartCounter") {
    voucherData.cartCount = action.payload;

    secureLocalStorage.setItem("voucherInfo", JSON.stringify(voucherData));

    return {
      ...state,
      cartCount: action.payload,
    };
  } else {
    return state;
  }
};
export default reducer;
