import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderUser from "./Includes/HeaderUser";
import FooterUser from "./Includes/FooterUser";
import UserListService from "../../services/user-list.service";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

export default function UserOrderShipping() {
  const [tableLoader, settableLoader] = useState(false);
  const [show, setShow] = useState(false);

  const [tableData, settableData] = useState([]);

  const { certi } = useParams();
  const state = useSelector((state) => state.stateVals);
  const { certificate } = state;

  const orderDetail = async () => {
    settableLoader(true);

    try {
      const responce = await UserListService.getOrderInfo(certi);

      const res = responce?.data?.response;

      settableData(res);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableData([]);
      settableLoader(false);
    }
  };

  useLayoutEffect(() => {
    if (certificate && certificate === certi && certi) {
      orderDetail();
      setShow(true);
    } else {
      window.location.href = "/viewreward";
    }
  }, []);

  return show ? (
    <div className="reward-body">
      <HeaderUser />
      <div className="main-content">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="user-form home_div">
                <h3 className="mb-2">ORDER SENT</h3>
              </div>

              {tableLoader ? (
                <Skeleton width="100%" count={1} height="250px" />
              ) : (
                <div className="m-ship">
                  <div className="text-left">
                    Dear {tableData?.fname}, your gift should arrive within 4
                    weeks.
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered align-middle">
                      <tbody>
                        <tr>
                          <td colSpan="2" align="center">
                            <h4>
                              <strong>Shipping Information</strong>
                            </h4>
                          </td>
                        </tr>

                        <tr>
                          <td width="5%">
                            <b className="fw-bold">Address</b>
                          </td>
                          <td>
                            {tableData?.address ? tableData?.address : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <b className="fw-bold">City</b>
                          </td>
                          <td>
                            {tableData?.address ? tableData?.city : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b className="fw-bold">Zip</b>
                          </td>
                          <td>{tableData?.address ? tableData?.zip : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>
                            <b className="fw-bold">Product Name</b>
                          </td>
                          <td>
                            {tableData?.product ? tableData?.product : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b className="fw-bold">Product Image</b>
                          </td>
                          <td>
                            {tableData?.prod_image ? (
                              <img
                                width="150px"
                                className="img-fluid"
                                src={`${process.env.REACT_APP_IMAGE_Link}${tableData?.prod_image}`}
                                alt="product"
                              />
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterUser />
    </div>
  ) : (
    <div className="white-screen"></div>
  );
}
