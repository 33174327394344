import React, { useLayoutEffect, useState } from "react";
import HeaderUser from "./Includes/HeaderUser";
import FooterUser from "./Includes/FooterUser";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { contactUser } from "../../schema";
import { toast } from "react-toastify";
import UserListService from "../../services/user-list.service";
import ReactLoading from "react-loading";

export default function ContactUs() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const state = useSelector((state) => state.stateVals);
  const { certificate } = state;
  const [errorsServer, setErrorsServer] = useState([]);
  const [formSubmiting, setFormSubmiting] = useState(false);

  useLayoutEffect(() => {
    if (certificate) {
      setShow(true);
    } else {
      navigate("/", { replace: true });
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, []);

  const makeContact = async (values, action) => {
    setFormSubmiting(true);

    try {
      const response = await UserListService.contactUs(values);

      action.resetForm();
      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setFormSubmiting(false);
    } catch (err) {
      setFormSubmiting(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.warn(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        let errorData = {};

        errorData["name"] =
          err.response?.data &&
          err.response?.data?.name &&
          err.response?.data?.name[0];

        errorData["email"] =
          err.response?.data &&
          err.response?.data?.email &&
          err.response?.data?.email[0];
        errorData["phone"] =
          err.response?.data &&
          err.response?.data?.phone &&
          err.response?.data?.phone[0];

        setErrorsServer(errorData);
      }
    }
  };

  const {
    values,
    errors,
    touched,

    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      subject: "",
      email: "",
      description: "",
      comments: "",
      phone: "",
    },
    validationSchema: contactUser,
    onSubmit: (values, action) => {
      makeContact(values, action);
    },
  });

  return show ? (
    <div className="reward-body">
      <HeaderUser />
      <div className="main-content">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="user-form home_div">
                <h3 className="mb-2">Contact Us</h3>
                <div className="m-ship">
                  <div
                    className="alert alert-primary d-flex align-items-center"
                    role="alert"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle-fill me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                    </svg>
                    <div>
                      Please make sure. More specific information from you helps
                      us provide a quick, accurate response.
                    </div>
                  </div>
                  <h2>Questions / Concerns / Comments</h2>

                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-md-6">
                      <div className="form-floating mt-3">
                        <select
                          className="form-select"
                          onChange={handleChange}
                          name="subject"
                          value={values.subject || ""}
                        >
                          <option value="" disabled={true}>
                            Choose an option
                          </option>
                          <option value="My Login / Password is not working">
                            My Login / Password is not working
                          </option>
                          <option value="How do I earn points?">
                            How do I earn points?
                          </option>
                          <option value="I dont have enough points to redeem my selection?">
                            I dont have enough points to redeem my selection?{" "}
                          </option>
                          <option value="I have a question about program structure?">
                            I have a question about program structure?{" "}
                          </option>
                          <option value="My program question is not listed here?">
                            My program question is not listed here?{" "}
                          </option>
                        </select>
                        <label>Subject</label>
                      </div>
                      {errors.subject && touched.subject ? (
                        <span className="text-danger error-message">
                          {errors.subject}
                        </span>
                      ) : errorsServer.subject ? (
                        <span className="text-danger error-message">
                          {errorsServer.subject}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mt-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Contact Name"
                          onChange={handleChange}
                          name="name"
                          value={values.name || ""}
                        />
                        <label>Contact Name</label>
                      </div>
                      {errors.name && touched.name ? (
                        <span className="text-danger error-message">
                          {errors.name}
                        </span>
                      ) : errorsServer.name ? (
                        <span className="text-danger error-message">
                          {errorsServer.name}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mt-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Contact Phone"
                          onChange={handleChange}
                          name="phone"
                          value={values.phone || ""}
                        />
                        <label>Contact Phone</label>
                        {errors.phone && touched.phone ? (
                          <span className="text-danger error-message">
                            {errors.phone}
                          </span>
                        ) : errorsServer.phone ? (
                          <span className="text-danger error-message">
                            {errorsServer.phone}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating mt-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Contact Email"
                          onChange={handleChange}
                          name="email"
                          value={values.email || ""}
                        />
                        <label>Contact Email</label>
                        {errors.email && touched.email ? (
                          <span className="text-danger error-message">
                            {errors.email}
                          </span>
                        ) : errorsServer.email ? (
                          <span className="text-danger error-message">
                            {errorsServer.email}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating mt-3">
                        <textarea
                          className="form-control"
                          placeholder="Brief Description"
                          onChange={handleChange}
                          name="description"
                          value={values.description || ""}
                        ></textarea>
                        <label>Brief Description</label>
                        {errors.description && touched.description ? (
                          <span className="text-danger error-message">
                            {errors.description}
                          </span>
                        ) : errorsServer.description ? (
                          <span className="text-danger error-message">
                            {errorsServer.description}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating mt-3">
                        <textarea
                          className="form-control"
                          placeholder="Questions / Comments"
                          onChange={handleChange}
                          name="comments"
                          value={values.comments || ""}
                        ></textarea>
                        <label>Questions / Comments</label>
                        {errors.comments && touched.comments ? (
                          <span className="text-danger error-message">
                            {errors.comments}
                          </span>
                        ) : errorsServer.comments ? (
                          <span className="text-danger error-message">
                            {errorsServer.comments}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-3 ms-auto mt-3">
                      <input
                        className="button less_points less_points2"
                        type="submit"
                        value="Submit"
                      />
                    </div>
                    <div className="col-md-3  mt-3">
                      <input
                        className="button less_points less_points2"
                        type="reset"
                        value="Reset"
                        onClick={() => window.location.reload(false)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterUser />
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  ) : (
    <div className="white-screen"></div>
  );
}
