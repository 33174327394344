import React, { useEffect, useState } from "react";
import Sidebar from "../includes/Sidebar";
import MainHeading from "../includes/MainHeading";
import Footer from "../includes/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ReactLoading from "react-loading";
import { updateVoucher } from "../../../schema";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import $ from "jquery";
import ImageLoader from "../includes/ImageLoader";

export default function EditVoucher() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [formSubmiting, setFormSubmiting] = useState(false);
  const [tableLoader, settableLoader] = useState(false);
  const [startPoint, setStartPoint] = useState(1);
  const [productShow, setProductShow] = useState("");
  const [giftError, setGiftError] = useState(false);
  const [endPoint, setEndPoint] = useState(500);
  const [tableData, settableData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [errorsServer, setErrorsServer] = useState([]);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();
  let searchTimer;

  const giftsLists = async (start, end) => {
    clearTimeout(searchTimer);

    if (
      parseInt(start) <= parseInt(end) &&
      parseInt(start) > 0 &&
      parseInt(end) > 0
    ) {
      try {
        setProductShow("ok");
        settableLoader(true);

        const responce = await AdminListService.getGiftsByRange(start, end);

        const res = responce?.data?.response;

        const results = [];
        res.map((value) => {
          return results.push({
            CardName: value.name,
            CardImage: value.image,
            CardId: value.id,
          });
        });

        settableData([...results]);

        settableLoader(false);
        setProductShow("ok");
        setStartPoint(start);
        setEndPoint(end);
      } catch (err) {
        console.log(err);
        settableData([]);
        settableLoader(false);
        setProductShow("fail");

        if (
          err?.response?.data?.message &&
          typeof err?.response?.data?.message === "string"
        ) {
          toast.error(err?.response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Something went wrong, try again", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } else {
      toast.error("Points Range Invalid ", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      settableData([]);
      settableLoader(false);
      setProductShow("fail");
    }
  };
  useEffect(() => {
    if (adminAccessToken) {
      const getSingleVoucher = async () => {
        setLoading(true);
        try {
          const response = await AdminListService.getVoucherByIdNew(id);

          setValues({
            Company: response?.data?.response?.company,
            Certificate: response?.data?.response?.certificate,

            First: response?.data?.response?.fname,
            Street: response?.data?.response?.address,
            State: response?.data?.response?.state,

            issuance:
              response?.data?.response?.issuance !== "" &&
              response?.data?.response?.issuance !== " " &&
              response?.data?.response?.issuance !== "0000-00-00" &&
              moment(response?.data?.response?.issuance).format("YYYY-MM-DD"),

            CertiStatus: response?.data?.response?.status,
            Job: response?.data?.response?.job,

            Last: response?.data?.response?.lname,
            City: response?.data?.response?.city,
            Zip: response?.data?.response?.zip,
            Expiration:
              response?.data?.response?.expiration !== "" &&
              response?.data?.response?.expiration !== " " &&
              response?.data?.response?.expiration !== "0000-00-00" &&
              moment(response?.data?.response?.expiration).format("YYYY-MM-DD"),
            Denomination: response?.data?.response?.denomination,
          });
          const res = response?.data?.response?.choices;

          setCardData(res);
          setLoading(false);
          setStartPoint(response?.data?.response?.denomination);
          setEndPoint(response?.data?.response?.denomination_end);
          giftsLists(
            response?.data?.response?.denomination,
            response?.data?.response?.denomination_end
          );
        } catch (err) {
          console.log(err);
          setLoading(false);

          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };
      getSingleVoucher();
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const updateVoucherFun = async (values) => {
    var selectedCards = await $(".on-off-cards input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");
    if (!selectedCards.length) {
      setGiftError(true);
    } else {
      if (
        parseInt(startPoint) <= parseInt(endPoint) &&
        parseInt(startPoint) > 0 &&
        parseInt(endPoint) > 0
      ) {
        setFormSubmiting(true);
        setGiftError(false);
        try {
          const response = await AdminListService.updateVoucherById(
            values,
            admin_id,
            id,
            selectedCards,
            startPoint,
            endPoint
          );

          toast.success(response?.data?.response, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setFormSubmiting(false);
          setTimeout(() => {
            navigate("/admin/vouchers", { replace: true });
          }, 1000);
        } catch (err) {
          console.log(err);
          setFormSubmiting(false);

          if (
            err?.response?.data?.response &&
            typeof err?.response?.data?.response === "string"
          ) {
            toast.error(err?.response?.data?.response, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            let errorData = {};

            errorData["Company"] =
              err.response?.data &&
              err.response?.data?.company &&
              err.response?.data?.company[0];
            errorData["Job"] =
              err.response?.data &&
              err.response?.data?.job &&
              err.response?.data?.job[0];
            errorData["Certificate"] =
              err.response?.data &&
              err.response?.data?.certificate &&
              err.response?.data?.certificate[0];
            errorData["Expiration"] =
              err.response?.data &&
              err.response?.data?.expiration &&
              err.response?.data?.expiration[0];

            errorData["First"] =
              err.response?.data &&
              err.response?.data?.fname &&
              err.response?.data?.fname[0];
            errorData["Last"] =
              err.response?.data &&
              err.response?.data?.lname &&
              err.response?.data?.lname[0];
            errorData["Street"] =
              err.response?.data &&
              err.response?.data?.address &&
              err.response?.data?.address[0];

            errorData["City"] =
              err.response?.data &&
              err.response?.data?.city &&
              err.response?.data?.city[0];

            errorData["State"] =
              err.response?.data &&
              err.response?.data?.state &&
              err.response?.data?.state[0];

            errorData["issuance"] =
              err.response?.data &&
              err.response?.data?.issuance &&
              err.response?.data?.issuance[0];

            errorData["Zip"] =
              err.response?.data &&
              err.response?.data?.zip &&
              err.response?.data?.zip[0];

            errorData["Denomination"] =
              err.response?.data &&
              err.response?.data?.denomination &&
              err.response?.data?.denomination[0];

            setErrorsServer(errorData);
            $("html, body").animate(
              {
                scrollTop: 0,
              },
              600
            );
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      } else {
        toast.error("Points Range Invalid ", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,

    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Company: "",
      Certificate: "",
      First: "",
      Street: "",
      State: "",
      issuance: "",
      CertiStatus: "",
      Job: "",
      Last: "",
      City: "",
      Zip: "",
      Expiration: "",
    },
    validationSchema: updateVoucher,
    onSubmit: (values) => {
      updateVoucherFun(values);
    },
  });

  const handleButtonClick = () => {
    setTimeout(() => {
      const firstError = document.querySelector(".error-message");
      if (firstError) {
        // firstError.scrollIntoView({ behavior: "smooth" });
        const rect = firstError.getBoundingClientRect();
        const offsetTop = rect.top - 200 + window.scrollY;

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Update Voucher" />

          <form className="mt-4 row" onSubmit={handleSubmit} noValidate>
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Edit Your Voucher</h4>
                </div>
                <div className="card-body">
                  {loading ? (
                    <Skeleton
                      count={20}
                      height="58px"
                      width="48.9%"
                      inline={true}
                      className="m-2"
                    />
                  ) : (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Company <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Company"
                            onChange={handleChange}
                            name="Company"
                            value={values.Company || ""}
                          />
                          {errors.Company && touched.Company ? (
                            <span className="text-danger error-message">
                              {errors.Company}
                            </span>
                          ) : errorsServer.Company ? (
                            <span className="text-danger error-message">
                              {errorsServer.Company}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Job <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Job"
                            onChange={handleChange}
                            name="Job"
                            value={values.Job || ""}
                          />
                          {errors.Job && touched.Job ? (
                            <span className="text-danger error-message">
                              {errors.Job}
                            </span>
                          ) : errorsServer.Job ? (
                            <span className="text-danger error-message">
                              {errorsServer.Job}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Certificate <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Certificate"
                            onChange={handleChange}
                            name="Certificate"
                            value={values.Certificate || ""}
                          />
                          {errors.Certificate && touched.Certificate ? (
                            <span className="text-danger error-message">
                              {errors.Certificate}
                            </span>
                          ) : errorsServer.Certificate ? (
                            <span className="text-danger error-message">
                              {errorsServer.Certificate}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First"
                            onChange={handleChange}
                            name="First"
                            value={values.First || ""}
                          />
                          {errors.First && touched.First ? (
                            <span className="text-danger error-message">
                              {errors.First}
                            </span>
                          ) : errorsServer.First ? (
                            <span className="text-danger error-message">
                              {errorsServer.First}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last"
                            onChange={handleChange}
                            name="Last"
                            value={values.Last || ""}
                          />
                          {errors.Last && touched.Last ? (
                            <span className="text-danger error-message">
                              {errors.Last}
                            </span>
                          ) : errorsServer.Last ? (
                            <span className="text-danger error-message">
                              {errorsServer.Last}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Address <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            onChange={handleChange}
                            name="Street"
                            value={values.Street || ""}
                          />
                          {errors.Street && touched.Street ? (
                            <span className="text-danger error-message">
                              {errors.Street}
                            </span>
                          ) : errorsServer.Street ? (
                            <span className="text-danger error-message">
                              {errorsServer.Street}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            City <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            onChange={handleChange}
                            name="City"
                            value={values.City || ""}
                          />
                          {errors.City && touched.City ? (
                            <span className="text-danger error-message">
                              {errors.City}
                            </span>
                          ) : errorsServer.City ? (
                            <span className="text-danger error-message">
                              {errorsServer.City}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            State <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            onChange={handleChange}
                            name="State"
                            value={values.State || ""}
                          />
                          {errors.State && touched.State ? (
                            <span className="text-danger error-message">
                              {errors.State}
                            </span>
                          ) : errorsServer.State ? (
                            <span className="text-danger error-message">
                              {errorsServer.State}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>
                            Zip <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip"
                            onChange={handleChange}
                            name="Zip"
                            value={values.Zip || ""}
                          />
                          {errors.Zip && touched.Zip ? (
                            <span className="text-danger error-message">
                              {errors.Zip}
                            </span>
                          ) : errorsServer.Zip ? (
                            <span className="text-danger error-message">
                              {errorsServer.Zip}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Begin Redemption</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            name="issuance"
                            value={values.issuance || ""}
                          />
                          {errors.issuance && touched.issuance ? (
                            <span className="text-danger error-message">
                              {errors.issuance}
                            </span>
                          ) : errorsServer.issuance ? (
                            <span className="text-danger error-message">
                              {errorsServer.issuance}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box">
                          <label>Expiration</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            name="Expiration"
                            value={values.Expiration || ""}
                          />
                          <span className="text-danger">
                            (MM/DD/YYYY) eg : 12/25/2014
                          </span>
                          {errors.Expiration && touched.Expiration ? (
                            <span className="text-danger error-message">
                              {errors.Expiration}
                            </span>
                          ) : errorsServer.Expiration ? (
                            <span className="text-danger error-message">
                              {errorsServer.Expiration}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-field-box mb-md-0">
                          <label>Certificate Status</label>
                          <input
                            type="text"
                            className={`form-control text-capitalize ${
                              values.CertiStatus === "active" ||
                              values.CertiStatus === "Valid"
                                ? "text-success"
                                : values.CertiStatus === "used"
                                ? "text-primary"
                                : values.CertiStatus === "expired" ||
                                  values.CertiStatus === "archive"
                                ? "text-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            name="CertiStatus"
                            value={values.CertiStatus || ""}
                            readOnly={true}
                          />
                          {errors.CertiStatus && touched.CertiStatus ? (
                            <span className="text-danger error-message">
                              {errors.CertiStatus}
                            </span>
                          ) : errorsServer.CertiStatus ? (
                            <span className="text-danger error-message">
                              {errorsServer.CertiStatus}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className="card-footer text-center">
                  <button
                    className="btn btn-primary "
                    type="submit"
                    onClick={handleButtonClick}
                  >
                    Update Voucher
                  </button>
                </div> */}
              </div>
            </div>
            <div className="mt-4  col-md-12 col-12">
              <div
                className={`card dashboard-cards ${
                  giftError ? "error-card" : ""
                }`}
              >
                {giftError ? (
                  <span className="text-danger">Please select a product</span>
                ) : null}
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="mb-0">Select Points Range For Products</h4>
                    </div>
                    <div className="col-md-4 text-end">
                      <button
                        className="btn btn-primary "
                        type="submit"
                        onClick={handleButtonClick}
                      >
                        Update Voucher
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="input-field-box mb-lg-0">
                        <label>From</label>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            min={1}
                            onChange={(e) => {
                              setStartPoint(e.target.value);
                              settableData([]);
                            }}
                            value={startPoint}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="input-field-box mb-lg-0">
                        <label>To</label>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            min={1}
                            onChange={(e) => {
                              setEndPoint(e.target.value);
                              settableData([]);
                            }}
                            value={endPoint}
                          />
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={(e) => {
                              giftsLists(startPoint, endPoint);
                            }}
                          >
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={(e) => {
                              window.location.reload();
                            }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gift-scroll gift-lists">
                  <div className="card-body on-off-cards">
                    {productShow === "ok" ? (
                      tableLoader ? (
                        <Skeleton
                          count={20}
                          height="180px"
                          width="18.9%"
                          inline={true}
                          className="m-2"
                        />
                      ) : (
                        <div className="row">
                          {tableData.length
                            ? tableData.map((el, index) => (
                                <div
                                  className="col-12 col-md-4 col-lg-2 text-center"
                                  key={index}
                                >
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={el.CardId}
                                      value={el.CardId}
                                      defaultChecked={
                                        cardData?.includes(el.CardId)
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      className="form-check-label form-check-label-with-text mt-2"
                                      htmlFor={el.CardId}
                                    >
                                      <span className="card-name-text">
                                        {el.CardName}
                                      </span>
                                      <ImageLoader cardImg={el.CardImage} />
                                    </label>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      )
                    ) : productShow === "fail" ? (
                      <h4 className="mb-0 text-danger">No Product Found</h4>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
      <Footer />
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  );
}
