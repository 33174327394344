import React, { useEffect } from "react";
import $ from "jquery";
import { Tooltip } from "react-tooltip";

export default function FooterUser() {
  useEffect(() => {
    $(window).on("scroll", function () {
      $(this).scrollTop() > 300
        ? $(".back-2-top").addClass("showbtn")
        : $(".back-2-top").removeClass("showbtn");
    });
  }, []);
  const backToTop = () => {
    return (
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      ),
      !1
    );
  };
  return (
    <>
      <footer className="login-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              © Copyright {new Date().getFullYear()}. All rights reserved by
              Elite Rewards.
              <p>
                <a href="mailto:customercare@eliterewards.biz">
                  customercare@eliterewards.biz
                </a>{" "}
                and <a href="tel:(866)354-8321">(866)354-8321</a> 8:00am to
                5:00pm EST, M-F.
              </p>
            </div>
          </div>
        </div>
      </footer>
      <button
        onClick={backToTop}
        className="btn btn-primary btn-lg back-2-top"
        data-tooltip-id="top-tip"
        data-tooltip-content="Click to return on the top page"
        data-tooltip-place="left"
      >
        <i className="fa fa-arrow-up" aria-hidden="true"></i>
      </button>
      <Tooltip
        id="top-tip"
        style={{
          padding: "2px 10px",
        }}
      />
    </>
  );
}
