import React, { useEffect, useState } from "react";
import Sidebar from "../includes/Sidebar";
import MainHeading from "../includes/MainHeading";
import Footer from "../includes/Footer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ReactLoading from "react-loading";
import { pavlinaUpdateusedVoucher } from "../../../schema";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import $ from "jquery";
import formatThousands from "format-thousands";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import Select from "react-select";

export default function PavlinaIndex() {
  const [loading, setLoading] = useState(false);
  const [formSubmiting, setFormSubmiting] = useState(false);
  const [tableLoader, settableLoader] = useState(false);
  const [showSearched, setshowSearched] = useState("");

  const [searchedData, setSearchedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("10");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");

  const [cardData, setCardData] = useState([]);
  const [errorsServer, setErrorsServer] = useState([]);
  const [EditId, setEditId] = useState("");
  const [certiStatus, setCertiStatus] = useState("");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const [jobInfo, setJobInfo] = useState([]);
  const [jobLoader, setJobLoader] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [exportLink, setExportLink] = useState("");

  const navigate = useNavigate();
  const giftsLists = async () => {
    settableLoader(true);

    try {
      const responce = await AdminListService.getAllGifts();

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          label: value.name,
          value: value.id,
        });
      });
      await setCardData([]);
      setTimeout(() => {
        setCardData([...results]);
      }, 500);
    } catch (err) {
      console.log(err);
      setCardData([]);
    }
  };
  const backToTop = () => {
    return (
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      ),
      !1
    );
  };
  const giftsListsForCertificate = async (id) => {
    settableLoader(true);

    try {
      const responce = await AdminListService.getAllGiftsForcetiId(id);

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          label: value.name,
          value: value.id,
        });
      });
      await setCardData([]);
      setTimeout(() => {
        setCardData([...results]);
      }, 500);
    } catch (err) {
      console.log(err);
      setCardData([]);
    }
  };
  const getSingleVoucher = async (id) => {
    setFormSubmiting(true);
    setLoading(true);
    setJobInfo([]);
    setSearchedData([]);

    try {
      const response = await AdminListService.getOrderById(id);

      setValues({
        Company: response?.data?.response?.company,
        Certificate: response?.data?.response?.certificate,
        product: response?.data?.response?.prod_id,
        productName: response?.data?.response?.product,
        First: response?.data?.response?.fname,
        State: response?.data?.response?.state,
        address1: response?.data?.response?.address1,
        email: response?.data?.response?.email,
        phone: response?.data?.response?.phone,
        issuance:
          response?.data?.response?.issuance !== "" &&
          response?.data?.response?.issuance !== " " &&
          response?.data?.response?.issuance !== "0000-00-00"
            ? moment(response?.data?.response?.issuance).format("YYYY-MM-DD")
            : "",
        redemDate:
          response?.data?.response?.use_date !== "" &&
          response?.data?.response?.use_date !== " " &&
          response?.data?.response?.use_date !== "0000-00-00"
            ? moment(response?.data?.response?.use_date).format("YYYY-MM-DD")
            : "",
        CertiStatus: response?.data?.response?.status,
        Job: response?.data?.response?.job,
        Last: response?.data?.response?.lname,
        City: response?.data?.response?.city,
        Zip: response?.data?.response?.zip,
        address1: response?.data?.response?.address,
        Expiration:
          response?.data?.response?.expiration !== "" &&
          response?.data?.response?.expiration !== " " &&
          response?.data?.response?.expiration !== "0000-00-00"
            ? moment(response?.data?.response?.expiration).format("YYYY-MM-DD")
            : "",
        Denomination: response?.data?.response?.denomination,
      });
      setCertiStatus(response?.data?.response?.status);
      // const res = response?.data?.response?.choices;
      giftsListsForCertificate(id);
      // setCardData(res);
      setEditId(id);
      backToTop();
      setLoading(false);
      setFormSubmiting(false);
    } catch (err) {
      setEditId("");
      console.log(err);
      setLoading(false);
      setFormSubmiting(false);

      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  useEffect(() => {
    if (adminAccessToken) {
      giftsLists();
      searchCertificate(values, "1", limit);
      const updateTimestamp = () => {
        const timestampInSeconds = Math.floor(Date.now() / 1000);
        setCurrentDateTime(timestampInSeconds);
      };

      const intervalId = setInterval(updateTimestamp, 1000);

      if (EditId !== "") {
        getSingleVoucher(EditId);
      }
      return () => clearInterval(intervalId);
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const updateVoucherFun = async (values, action) => {
    // var selectedCards = await $(".on-off-cards input:checkbox:checked")
    //   .map(function () {
    //     return this.value;
    //   })
    //   .get()
    //   .join(",");
    // if (!selectedCards.length) {
    //   toast.error("Please select Gift!", {
    //     position: "top-center",
    //     autoClose: 3000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // } else {
    // }
    setFormSubmiting(true);
    try {
      const response = await AdminListService.updateVoucherByIdByPavlina(
        values,
        admin_id,
        EditId
      );

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSearchedData([]);
      setTotalPages(0);
      setTotalResults(0);
      setCurrentPage(0);
      setLimit("10");
      setEditId("");
      await action.resetForm();
      giftsLists();
      setFormSubmiting(false);
      setTimeout(() => {
        const dropArea = document.querySelector(
            "form .card.dashboard-cards .card-footer"
          ),
          input = dropArea.querySelector("button");

        input.click();
      }, 500);
    } catch (err) {
      console.log(err);
      setFormSubmiting(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        let errorData = {};

        errorData["Company"] =
          err.response?.data &&
          err.response?.data?.company &&
          err.response?.data?.company[0];
        errorData["Job"] =
          err.response?.data &&
          err.response?.data?.job &&
          err.response?.data?.job[0];
        errorData["Certificate"] =
          err.response?.data &&
          err.response?.data?.certificate &&
          err.response?.data?.certificate[0];
        errorData["Expiration"] =
          err.response?.data &&
          err.response?.data?.expiration &&
          err.response?.data?.expiration[0];
        errorData["Concatenated"] =
          err.response?.data &&
          err.response?.data?.concatenated &&
          err.response?.data?.concatenated[0];
        errorData["Amount"] =
          err.response?.data &&
          err.response?.data?.amount &&
          err.response?.data?.amount[0];
        errorData["shipCost"] =
          err.response?.data &&
          err.response?.data?.shipCost &&
          err.response?.data?.shipCost[0];
        errorData["Shipped"] =
          err.response?.data &&
          err.response?.data?.shipped &&
          err.response?.data?.shipped[0];
        errorData["Received"] =
          err.response?.data &&
          err.response?.data?.received &&
          err.response?.data?.received[0];

        errorData["First"] =
          err.response?.data &&
          err.response?.data?.fname &&
          err.response?.data?.fname[0];
        errorData["Last"] =
          err.response?.data &&
          err.response?.data?.lname &&
          err.response?.data?.lname[0];
        errorData["email"] =
          err.response?.data &&
          err.response?.data?.email &&
          err.response?.data?.email[0];
        errorData["phone"] =
          err.response?.data &&
          err.response?.data?.phone &&
          err.response?.data?.phone[0];

        errorData["City"] =
          err.response?.data &&
          err.response?.data?.city &&
          err.response?.data?.city[0];

        errorData["State"] =
          err.response?.data &&
          err.response?.data?.state &&
          err.response?.data?.state[0];

        errorData["issuance"] =
          err.response?.data &&
          err.response?.data?.issuance &&
          err.response?.data?.issuance[0];
        errorData["redemDate"] =
          err.response?.data &&
          err.response?.data?.created_at &&
          err.response?.data?.created_at[0];

        errorData["Fulfilled"] =
          err.response?.data &&
          err.response?.data?.fulfilled &&
          err.response?.data?.fulfilled[0];

        errorData["Zip"] =
          err.response?.data &&
          err.response?.data?.zip &&
          err.response?.data?.zip[0];

        errorData["product"] =
          err.response?.data &&
          err.response?.data?.product &&
          err.response?.data?.product[0];

        errorData["Denomination"] =
          err.response?.data &&
          err.response?.data?.denomination &&
          err.response?.data?.denomination[0];

        setErrorsServer(errorData);
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          600
        );
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const jobInfoByJob = async (job) => {
    setJobLoader(true);
    try {
      const responce = await AdminListService.getJobDetailInfoByJob(job);

      const res = responce?.data?.response;

      const results = [];

      results.push({
        job: res.job,
        job_date: res.job_dates,
        job_percentage: res.used_percentage,
        total_cert: res.total_certificates,
        total_used_cert: res.total_used_certificates,
      });

      setJobInfo([...results]);
      setJobLoader(false);
    } catch (err) {
      console.log(err);
      setJobLoader(false);

      setJobInfo([]);
    }
  };

  const searchCertificate = async (values, page, limit) => {
    setFormSubmiting(true);
    try {
      const responce = await AdminListService.getCertificateBySearch(
        values,
        page,
        limit
      );

      setExportLink(
        `${process.env.REACT_APP_API_Link}export-pavlina-record-syg?company=${values.Company}&job=${values.Job}&certificate=${values.Certificate}&expiration=${values.Expiration}&status=${values.CertiStatus}&fname=${values.First}&lname=${values.Last}&address1=${values.address1}&email=${values.email}&phone=${values.phone}&city=${values.City}&state=${values.State}&zip=${values.Zip}&issuance=${values.issuance}&denomination=${values.Denomination}&use_date=${values.redemDate}&product=${values.product}&time=${currentDateTime}`
      );

      if (values.Certificate !== "" && responce?.data?.response?.length === 1) {
        getSingleVoucher(responce?.data?.response[0].id);
      }
      if (values.Job !== "") {
        jobInfoByJob(values.Job);
      }

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          address1: value.address,
          address2: value.address2,
          city: value.city,
          email: value.email,
          fname: value.fname,
          lname: value.lname,
          issuance: value.issuance,
          expiration: value.expiration,
          use_date: value.use_date,
          status: value.status,
          job: value.job,
          voucher: value.voucher,
          cost: value.denomination,
          certificate: value.certificate,
          CUSTOMER: value.company,
          phone: value.phone,
          state: value.state,
          vocher: value.vocher,
          product: value.product,
          zip: value.zip,

          id: value.id,
        });
      });

      setSearchedData([...results]);
      setTotalPages(responce?.data?.total_pages);
      setTotalResults(responce?.data?.records);
      setCurrentPage(responce?.data?.current_page);
      setLimit(responce?.data?.per_page);
      setshowSearched("200");

      setFormSubmiting(false);
    } catch (err) {
      console.log(err);
      setshowSearched("404");
      setSearchedData([]);
      setTotalPages(0);
      setTotalResults(0);
      setCurrentPage(0);
      setLimit("10");
      setFormSubmiting(false);
      setExportLink("");
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    setFieldTouched,

    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Company: "",
      Certificate: "",

      First: "",
      address1: "",
      State: "",
      issuance: "",
      redemDate: "",
      CertiStatus: "used",
      Job: "",

      Last: "",
      City: "",
      Zip: "",
      Expiration: "",
      Denomination: "",
      email: "",
      phone: "",
      product: "",
      productName: "",
    },
    validationSchema: EditId !== "" ? pavlinaUpdateusedVoucher : "",
    onSubmit: (values, action) => {
      if (EditId !== "") {
        updateVoucherFun(values, action);
      } else {
        searchCertificate(values, "1", limit);
      }
    },
  });

  const handleButtonClick = () => {
    setTimeout(() => {
      const firstError = document.querySelector(".error-message");
      if (firstError) {
        // firstError.scrollIntoView({ behavior: "smooth" });
        const rect = firstError.getBoundingClientRect();
        const offsetTop = rect.top - 200 + window.scrollY;

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  const delVoucher = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleVouchers(
            delId,
            admin_id
          );

          searchCertificate(values, currentPage, limit);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const archiveVoucher = async (order_id) => {
    settableLoader(true);
    try {
      const response = await AdminListService.voucherArchive(
        admin_id,
        order_id
      );

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      settableLoader(false);
      setTimeout(() => {
        searchCertificate(values, currentPage, limit);
      }, 1000);
    } catch (err) {
      console.log(err);
      settableLoader(false);

      if (
        err?.response?.data?.error &&
        typeof err?.response?.data?.error === "string"
      ) {
        toast.error(err?.response?.data?.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  // const searchVoucher = useFormik({
  //   enableReinitialize: true,

  //   initialValues: {
  //     fieldtype: "",
  //     searchval: "",
  //   },
  //   validationSchema: quizSearch,
  //   onSubmit: (values) => {
  //     searchCertificate(values, "1", limit);
  //   },
  // });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading
            heading={EditId !== "" ? "Update Order" : "Search Order"}
          />

          <form className="mt-4 row" onSubmit={handleSubmit} noValidate>
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">
                    {EditId !== "" ? "Edit Your Order" : "Search Your Order"}
                  </h4>
                </div>
                <div className="card-body">
                  {loading ? (
                    <Skeleton
                      count={23}
                      height="58px"
                      width="23.9%"
                      inline={true}
                      className="m-2"
                    />
                  ) : (
                    <div className="row">
                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            Company{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Company"
                            onChange={handleChange}
                            name="Company"
                            value={values.Company || ""}
                          />
                          {errors.Company && touched.Company ? (
                            <span className="text-danger error-message">
                              {errors.Company}
                            </span>
                          ) : errorsServer.Company ? (
                            <span className="text-danger error-message">
                              {errorsServer.Company}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            Job{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Job"
                            onChange={handleChange}
                            name="Job"
                            value={values.Job || ""}
                          />
                          {errors.Job && touched.Job ? (
                            <span className="text-danger error-message">
                              {errors.Job}
                            </span>
                          ) : errorsServer.Job ? (
                            <span className="text-danger error-message">
                              {errorsServer.Job}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            Certificate{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Certificate"
                            onChange={handleChange}
                            name="Certificate"
                            value={values.Certificate || ""}
                          />
                          {errors.Certificate && touched.Certificate ? (
                            <span className="text-danger error-message">
                              {errors.Certificate}
                            </span>
                          ) : errorsServer.Certificate ? (
                            <span className="text-danger error-message">
                              {errorsServer.Certificate}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            First Name
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First"
                            onChange={handleChange}
                            name="First"
                            value={values.First || ""}
                            // disabled={
                            //   EditId !== ""
                            //     ? values.CertiStatus === "used" ||
                            //       values.CertiStatus === ""
                            //       ? false
                            //       : true
                            //     : false
                            // }
                          />
                          {errors.First && touched.First ? (
                            <span className="text-danger error-message">
                              {errors.First}
                            </span>
                          ) : errorsServer.First ? (
                            <span className="text-danger error-message">
                              {errorsServer.First}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            Last Name
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last"
                            onChange={handleChange}
                            name="Last"
                            value={values.Last || ""}
                            // disabled={
                            //   EditId !== ""
                            //     ? values.CertiStatus === "used" ||
                            //       values.CertiStatus === ""
                            //       ? false
                            //       : true
                            //     : false
                            // }
                          />
                          {errors.Last && touched.Last ? (
                            <span className="text-danger error-message">
                              {errors.Last}
                            </span>
                          ) : errorsServer.Last ? (
                            <span className="text-danger error-message">
                              {errorsServer.Last}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            Email{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            onChange={handleChange}
                            name="email"
                            value={values.email || ""}
                            // disabled={
                            //   EditId !== ""
                            //     ? values.CertiStatus === "used" ||
                            //       values.CertiStatus === ""
                            //       ? false
                            //       : true
                            //     : false
                            // }
                          />
                          {errors.email && touched.email ? (
                            <span className="text-danger error-message">
                              {errors.email}
                            </span>
                          ) : errorsServer.email ? (
                            <span className="text-danger error-message">
                              {errorsServer.email}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            Phone{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone"
                            onChange={handleChange}
                            name="phone"
                            value={values.phone || ""}
                            // disabled={
                            //   EditId !== ""
                            //     ? values.CertiStatus === "used" ||
                            //       values.CertiStatus === ""
                            //       ? false
                            //       : true
                            //     : false
                            // }
                          />
                          {errors.phone && touched.phone ? (
                            <span className="text-danger error-message">
                              {errors.phone}
                            </span>
                          ) : errorsServer.phone ? (
                            <span className="text-danger error-message">
                              {errorsServer.phone}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            Address{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            onChange={handleChange}
                            name="address1"
                            value={values.address1 || ""}
                            // disabled={
                            //   EditId !== ""
                            //     ? values.CertiStatus === "used" ||
                            //       values.CertiStatus === ""
                            //       ? false
                            //       : true
                            //     : false
                            // }
                          />
                          {errors.address1 && touched.address1 ? (
                            <span className="text-danger error-message">
                              {errors.address1}
                            </span>
                          ) : errorsServer.address1 ? (
                            <span className="text-danger error-message">
                              {errorsServer.address1}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            City{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            onChange={handleChange}
                            name="City"
                            value={values.City || ""}
                            // disabled={
                            //   EditId !== ""
                            //     ? values.CertiStatus === "used" ||
                            //       values.CertiStatus === ""
                            //       ? false
                            //       : true
                            //     : false
                            // }
                          />
                          {errors.City && touched.City ? (
                            <span className="text-danger error-message">
                              {errors.City}
                            </span>
                          ) : errorsServer.City ? (
                            <span className="text-danger error-message">
                              {errorsServer.City}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            State{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            onChange={handleChange}
                            name="State"
                            value={values.State || ""}
                            // disabled={
                            //   EditId !== ""
                            //     ? values.CertiStatus === "used" ||
                            //       values.CertiStatus === ""
                            //       ? false
                            //       : true
                            //     : false
                            // }
                          />
                          {errors.State && touched.State ? (
                            <span className="text-danger error-message">
                              {errors.State}
                            </span>
                          ) : errorsServer.State ? (
                            <span className="text-danger error-message">
                              {errorsServer.State}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            Zip{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip"
                            onChange={handleChange}
                            name="Zip"
                            value={values.Zip || ""}
                            // disabled={
                            //   EditId !== ""
                            //     ? values.CertiStatus === "used" ||
                            //       values.CertiStatus === ""
                            //       ? false
                            //       : true
                            //     : false
                            // }
                          />
                          {errors.Zip && touched.Zip ? (
                            <span className="text-danger error-message">
                              {errors.Zip}
                            </span>
                          ) : errorsServer.Zip ? (
                            <span className="text-danger error-message">
                              {errorsServer.Zip}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>
                            Redemption Date{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            name="redemDate"
                            value={values.redemDate || ""}
                            // disabled={
                            //   EditId !== ""
                            //     ? values.CertiStatus === "used" ||
                            //       values.CertiStatus === ""
                            //       ? false
                            //       : true
                            //     : false
                            // }
                          />
                          {errors.redemDate && touched.redemDate ? (
                            <span className="text-danger error-message">
                              {errors.redemDate}
                            </span>
                          ) : errorsServer.redemDate ? (
                            <span className="text-danger error-message">
                              {errorsServer.redemDate}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>Begin Redemption</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            name="issuance"
                            value={values.issuance || ""}
                            disabled={true}
                          />
                          {errors.issuance && touched.issuance ? (
                            <span className="text-danger error-message">
                              {errors.issuance}
                            </span>
                          ) : errorsServer.issuance ? (
                            <span className="text-danger error-message">
                              {errorsServer.issuance}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field-box">
                          <label>Expiration</label>
                          <input
                            type="date"
                            className="form-control"
                            onChange={handleChange}
                            name="Expiration"
                            value={values.Expiration || ""}
                            disabled={true}
                          />

                          {errors.Expiration && touched.Expiration ? (
                            <span className="text-danger error-message">
                              {errors.Expiration}
                            </span>
                          ) : errorsServer.Expiration ? (
                            <span className="text-danger error-message">
                              {errorsServer.Expiration}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field-box mb-md-0">
                          <label>
                            Select Gift{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          {cardData.length ? (
                            <Select
                              // isDisabled={
                              //   EditId !== ""
                              //     ? values.CertiStatus === "used" ||
                              //       values.CertiStatus === ""
                              //       ? false
                              //       : true
                              //     : false
                              // }
                              classNamePrefix="react-select"
                              className={`select_1 dropdown`}
                              placeholder={
                                EditId !== "" &&
                                values.product !== "" &&
                                values.productName !== ""
                                  ? values.productName
                                  : "Select Gift"
                              }
                              options={cardData}
                              isSearchable={true}
                              isClearable={true}
                              tabIndex="5"
                              name="product"
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  setFieldValue(
                                    "product",
                                    selectedOption.value
                                  );
                                } else {
                                  setFieldValue("product", "");
                                }
                              }}
                            />
                          ) : (
                            <Skeleton width="100%" count={1} height="55px" />
                          )}
                          {errors.product && touched.product ? (
                            <span className="text-danger error-message">
                              {errors.product}
                            </span>
                          ) : errorsServer.product ? (
                            <span className="text-danger error-message">
                              {errorsServer.product}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-field-box mb-md-0">
                          <label>
                            Certificate Status{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <select
                            className={`form-select text-capitalize ${
                              values.CertiStatus === "active" ||
                              values.CertiStatus === "Valid"
                                ? "text-success"
                                : values.CertiStatus === "used"
                                ? "text-primary"
                                : values.CertiStatus === "expired" ||
                                  values.CertiStatus === "archive"
                                ? "text-danger"
                                : ""
                            }`}
                            name="CertiStatus"
                            onChange={(e) => {
                              handleChange(e);
                              setCertiStatus(e.target.value);
                            }}
                            // onChange={handleChange}

                            value={values.CertiStatus || ""}
                          >
                            <option value="">Select Status</option>
                            <option value="active">Active</option>
                            <option value="used">Used</option>
                            <option value="expired">Expired</option>
                          </select>
                          {/* <input
                            type="text"
                            className={`form-control text-capitalize ${
                              values.CertiStatus === "active" ||
                              values.CertiStatus === "Valid"
                                ? "text-success"
                                : values.CertiStatus === "used" || values.CertiStatus !== ""
                                ? "text-pr=mary"
                                : values.CertiStatus === "expired" ||
                                  values.CertiStatus === "archive"
                                ? "text-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            
                            name="CertiStatus"
                            value={values.CertiStatus || ""}
                            readOnly={true}
                          /> */}
                          {errors.CertiStatus && touched.CertiStatus ? (
                            <span className="text-danger error-message">
                              {errors.CertiStatus}
                            </span>
                          ) : errorsServer.CertiStatus ? (
                            <span className="text-danger error-message">
                              {errorsServer.CertiStatus}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="input-field-box mb-0">
                          <label>
                            Denomination{" "}
                            {EditId !== "" ? (
                              <span className="text-danger">*</span>
                            ) : null}
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Denomination"
                            onChange={handleChange}
                            name="Denomination"
                            value={values.Denomination || ""}
                          />
                          {errors.Denomination && touched.Denomination ? (
                            <span className="text-danger error-message">
                              The denomination must be a number.
                            </span>
                          ) : errorsServer.Denomination ? (
                            <span className="text-danger error-message">
                              {errorsServer.Denomination}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-footer">
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary my-2 "
                      type="submit"
                      onClick={handleButtonClick}
                    >
                      {EditId !== "" ? "Update Order" : "Search Order"}
                    </button>
                    {EditId !== "" ? (
                      <button
                        className="btn btn-primary my-2 ms-2"
                        type="reset"
                        onClick={() => window.location.reload(false)}
                      >
                        Reset Form
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </form>
          {EditId !== "" ? (
            <></>
          ) : // <div className="mt-4  col-md-12 col-12">
          //   <div className="card dashboard-cards">
          //     <div className="bg-white  py-3 card-header">
          //       <h4 className="mb-0">Select Gifts</h4>
          //     </div>
          //     {tableLoader ? (
          //       <Skeleton
          //         count={20}
          //         height="180px"
          //         width="18.9%"
          //         inline={true}
          //         className="m-2"
          //       />
          //     ) : (
          //       <div className="gift-scroll">
          //         <div className="card-body on-off-cards">
          //           <div className="row">
          //             {tableData.length
          //               ? tableData.map((el, index) => (
          //                   <div
          //                     className="col-12 col-md-4 col-lg-2 text-center"
          //                     key={index}
          //                   >
          //                     <div className="form-check form-switch">
          //                       <input
          //                         className="form-check-input"
          //                         type="checkbox"
          //                         id={el.CardId}
          //                         value={el.CardName}
          //                         defaultChecked={
          //                           cardData?.includes(el.CardName)
          //                             ? true
          //                             : false
          //                         }
          //                       />
          //                       <label
          //                         className="form-check-label form-check-label-with-text mt-2"
          //                         htmlFor={el.CardId}
          //                       >
          //                         <span className="card-name-text">
          //                           {el.CardName}
          //                         </span>
          //                         <ImageLoader cardImg={el.CardImage} />
          //                       </label>
          //                     </div>
          //                   </div>
          //                 ))
          //               : null}
          //           </div>
          //         </div>
          //       </div>
          //     )}

          //     <div className="card-footer">
          //       <div className="col-12 text-center">
          //         <button
          //           className="btn btn-primary my-2 "
          //           type="submit"
          //           onClick={handleButtonClick}
          //         >
          //           Update Voucher
          //         </button>
          //       </div>
          //     </div>
          //   </div>
          // </div>

          searchedData.length && showSearched === "200" ? (
            <>
              <div className="mt-4  col-md-12 col-12">
                <div className="card dashboard-cards mt-4">
                  <div className="bg-white  py-3 card-header">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="mb-0">
                          Total Orders:{" "}
                          <span className="text-primary-color fw-bold">
                            {formatThousands(totalResults, {
                              separator: ",",
                            })}
                          </span>
                        </h4>
                      </div>
                      <div className="col-md-6 text-end">
                        <a
                          className="btn btn-primary btn-sm btn-icon-text"
                          href={exportLink}
                        >
                          Export Orders <i className="bi bi-filetype-exe"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {/* <form
                  className="row"
                  onSubmit={searchVoucher.handleSubmit}
                  noValidate
                >
                  <div className="col-lg-3">
                    <div className="input-field-box mb-lg-0">
                      <label>Show Column</label>
                      <select
                        className={`form-select ${
                          searchVoucher.errors.fieldtype &&
                          searchVoucher.touched.fieldtype
                            ? "is-danger"
                            : ""
                        }`}
                        onChange={searchVoucher.handleChange}
                      
                        name="fieldtype"
                        value={searchVoucher.values.fieldtype || ""}
                        required
                      >
                        <option value="">Select Column</option>
                        <option value="certificate">Certificate</option>
                        <option value="fname">First Name </option>
                        <option value="lname">Last Name </option>
                        <option value="address1">Address</option>
                        <option value="city">City</option>
                        <option value="state">State</option>
                        <option value="zip">Zip</option>
                        <option value="phone">Phone </option>
                        <option value="email">Email </option>
                        <option value="job">Job </option>
                        <option value="issuance">Begin Redemption </option>
                        <option value="expiration">Expiration </option>
                      </select>
                    </div>
                    {searchVoucher.errors.fieldtype &&
                    searchVoucher.touched.fieldtype ? (
                      <span className="text-danger">
                        {searchVoucher.errors.fieldtype}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-lg-5">
                    <div className="input-field-box mb-lg-0">
                      <label>Search</label>
                      <div className="input-group">
                        <input
                          type={
                            searchVoucher.values.fieldtype === "issuance" ||
                            searchVoucher.values.fieldtype === "expiration"
                              ? "date"
                              : "text"
                          }
                          className={`form-control ${
                            searchVoucher.errors.searchval &&
                            searchVoucher.touched.searchval
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={searchVoucher.handleChange}
                         
                          name="searchval"
                          value={searchVoucher.values.searchval || ""}
                          required
                        />

                        <button className="btn btn-primary " type="submit">
                          Search
                        </button>
                        <button
                          className="btn btn-primary"
                          type="reset"
                          onClick={() => window.location.reload(false)}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                    {searchVoucher.errors.searchval &&
                    searchVoucher.touched.searchval ? (
                      <span className="text-danger">
                        {searchVoucher.errors.searchval}
                      </span>
                    ) : null}
                  </div>

                </form> */}
                    <div className="col-lg-2 ms-auto">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            searchCertificate(values, "1", e.target.value)
                          }
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                    {jobInfo.length ? (
                      <div className="col-12 mb-1 mt-3">
                        {jobLoader ? (
                          <Skeleton count={1} height="50px" />
                        ) : (
                          jobInfo.map((el, index) => (
                            <div className="border-job-info" key={index}>
                              <div className="row">
                                <div className="col-lg-2">
                                  <strong>Job Number</strong>
                                  <span className="text-control">{el.job}</span>
                                </div>
                                <div className="col-lg-2">
                                  <strong>Total Certificates </strong>
                                  <span className="text-control">
                                    {el.total_cert}
                                  </span>
                                </div>
                                <div className="col-lg-2">
                                  <strong>Total Used Certificates </strong>
                                  <span className="text-control">
                                    {el.total_used_cert}
                                  </span>
                                </div>
                                <div className="col-lg-2">
                                  <strong>
                                    Total Percentage Used Certificates
                                  </strong>
                                  <span className="text-control">
                                    {el.job_percentage}%
                                  </span>
                                </div>
                                <div className="col-lg-2">
                                  <strong>Begin Redemption </strong>
                                  <span className="text-control">
                                    {el.job_date?.issuance &&
                                    el.job_date?.issuance !== "" &&
                                    el.job_date?.issuance !== "0000-00-00"
                                      ? moment(el.job_date?.issuance).format(
                                          "MM-DD-YYYY"
                                        )
                                      : "N/A"}
                                  </span>
                                </div>
                                <div className="col-lg-2">
                                  <strong>Expiration</strong>
                                  <span className=" text-control text-danger">
                                    {el.job_date?.expiration &&
                                    el.job_date?.expiration !== "" &&
                                    el.job_date?.expiration !== "0000-00-00"
                                      ? moment(el.job_date?.expiration).format(
                                          "MM-DD-YYYY"
                                        )
                                      : "N/A"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div className="table2-responsive">
                    <table className="text-nowrap  table">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col" width="50px">
                            {" "}
                            Job No
                          </th>

                          <th scope="col">Company Info</th>
                          <th scope="col">Basic Info</th>
                          <th scope="col">Date</th>
                          <th scope="col">Product</th>
                          <th scope="col">Status</th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchedData.length ? (
                          searchedData.map((el, index) => (
                            <tr key={index}>
                              <td className="align-middle">
                                {currentPage === 1
                                  ? index + 1
                                  : (currentPage - 1) * limit + index + 1}
                              </td>
                              <td className="align-middle">
                                <span className="text-job-control">
                                  <Link to={`/admin/job-detail/${el.job}`}>
                                    {el.job}
                                  </Link>
                                </span>
                              </td>
                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Certificate:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.certificate}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Points:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.cost}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Company:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.CUSTOMER}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>First Name:</strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.fname}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Last Name:</strong>
                                      </td>

                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.lname}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <strong>Address1:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.address1}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Address2:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.address2}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Zip:</strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.zip}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>City:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.city}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>State:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.state}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <strong>Phone:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.phone}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Email:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.email}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Begin Date:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control date-span">
                                          {el.issuance !== "" &&
                                            el.issuance !== "0000-00-00" &&
                                            moment(el.issuance).format(
                                              "MM-DD-YYYY"
                                            )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Expiration Date:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control date-span">
                                          {el.expiration !== "" &&
                                            el.expiration !== "0000-00-00" &&
                                            moment(el.expiration).format(
                                              "MM-DD-YYYY"
                                            )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Redeemed Date:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control date-span">
                                          {el.use_date !== "" &&
                                            el.use_date &&
                                            el.use_date !== "0000-00-00" &&
                                            moment(el.use_date).format(
                                              "MM-DD-YYYY"
                                            )}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>

                              <td className="align-middle">
                                <span className="text-control">
                                  {el.product ? el.product : "N/A"}
                                </span>
                              </td>
                              <td className="align-middle">
                                <span
                                  className={`text-control font-17 text-capitalize ${
                                    el.status === "active" ||
                                    el.status === "Valid"
                                      ? "text-success"
                                      : el.status === "used"
                                      ? "text-primary"
                                      : el.status === "expired" ||
                                        el.status === "archive"
                                      ? "text-danger"
                                      : ""
                                  }`}
                                >
                                  {el.status !== "" ? el.status : "N/A"}
                                </span>
                              </td>

                              <td className="align-middle text-center">
                                <div className="">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      getSingleVoucher(el.id);
                                    }}
                                    className="btn btn-success btn-sm btn-icon-text w-adjust"
                                  >
                                    Edit <i className="bi bi-pencil-square"></i>
                                  </button>
                                  <br />

                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm btn-icon-text my-3 w-adjust"
                                    onClick={() => {
                                      delVoucher(el.id);
                                    }}
                                  >
                                    Delete <i className="bi bi-x-circle"></i>
                                  </button>
                                  <br />
                                  <button
                                    onClick={() => {
                                      archiveVoucher(el.id);
                                    }}
                                    className="btn btn-success btn-sm btn-icon-text w-adjust"
                                  >
                                    Archive <i className="bi bi bi-archive"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="8"
                              className="text-center text-capitalize"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {totalResults > limit && totalPages > 1 ? (
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={parseInt(limit)}
                        totalItemsCount={totalResults}
                        onChange={(e) => {
                          searchCertificate(values, e, limit);
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First Page"
                        lastPageText="Last Page"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : showSearched === "404" ? (
            <div className="text-danger mt-3 font-22">No Record Found</div>
          ) : null}
        </div>
      </main>
      <Footer />
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#185791"
          className="bar-loader"
        />
      )}
    </div>
  );
}
