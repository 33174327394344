import React from "react";
import disclaimer from "../../../Images/disclaimer.png";

export default function Shipping() {
  return (
    <>
      <div className="new-heading">
        <h1>
          <img src={disclaimer} alt="shipping" />
          SHIPPING DISCLAIMER
        </h1>
      </div>
      <div className="cont-text">
        <p>Your certificate number is good for one gift only.</p>
      </div>
    </>
  );
}
