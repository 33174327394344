import React from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

export default function ProductLoader({ cardImg }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading ? <Skeleton count={1} height="245px" /> : null}

      <img
        className="img-fluid"
        src={`${process.env.REACT_APP_IMAGE_Link}${cardImg}`}
        alt="product"
        style={isLoading ? { display: "none" } : {}}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
}
