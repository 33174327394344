import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";

import moment from "moment/moment";
import MainHeading from "../includes/MainHeading";
import Skeleton from "react-loading-skeleton";
import formatThousands from "format-thousands";
import Swal from "sweetalert2";

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [tableLoader, settableLoader] = useState(false);
  const [counters, setCounters] = useState([]);
  const [tableData, settableData] = useState([]);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  const latestOrders = async () => {
    settableLoader(true);
    try {
      const responce = await AdminListService.latestOrder();

      const res = responce.data.response;
      const results = [];
      res.map((value) => {
        return results.push({
          userName: value.fname + " " + value.lname,
          email: value.email,
          regDate: value.created_at,
          gift: value.product,
          id: value.id,
          archive: value.archive,
        });
      });

      settableData([...results]);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableLoader(false);
      settableData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      const counters = async (values) => {
        setLoading(true);
        try {
          const response = await AdminListService.getCounts(values);

          setCounters(response.data);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);

          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };
      counters();

      latestOrders();
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);
  const archiveOrder = async (order_id) => {
    setLoading(true);
    try {
      const response = await AdminListService.orderArchive(admin_id, order_id);

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      setTimeout(() => {
        latestOrders();
      }, 1000);
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (
        err?.response?.data?.error &&
        typeof err?.response?.data?.error === "string"
      ) {
        toast.error(err?.response?.data?.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const delOrder = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleOrders(
            delId,
            admin_id
          );

          latestOrders();

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };
  const Table = ({ data }) => {
    return (
      <div className="table-responsive">
        <table className="text-nowrap mb-0 table">
          <thead className="table-light">
            <tr>
              <th scope="col">No</th>

              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Product</th>
              <th scope="col">Order Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data?.map((el, index) => (
                <tr key={index}>
                  <td className="align-middle">{index + 1} </td>
                  <td className="align-middle">{el.userName} </td>
                  <td className="align-middle">{el.email} </td>
                  <td className="align-middle">
                    <span className="text-control">{el.gift}</span>
                  </td>
                  <td className="align-middle">
                    {moment(el.regDate).format("MM-DD-YYYY")}
                  </td>

                  {/* <td className="align-middle">
                    <div className="d-flex align-items-center">
                      <div className="lh-1">
                        <h5 className=" mb-1">{el.gift}</h5>
                      </div>
                    </div>
                  </td> */}
                  {/* <td class="align-middle ">
                    <div class="d-flex align-items-center">
                      <button
                        type="button"
                        class="btn btn-success btn-sm btn-icon-text me-3"
                      >
                        Edit <i class="bi bi-pencil-square"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm btn-icon-text"
                      >
                        Delete <i class="bi bi-x-circle"></i>
                      </button>
                    </div>
                  </td> */}

                  <td className="align-middle ">
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-success btn-sm btn-icon-text me-2"
                        onClick={() => {
                          archiveOrder(el.id);
                        }}
                      >
                        Archive <i className="bi bi-archive"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm btn-icon-text"
                        onClick={() => {
                          delOrder(el.id);
                        }}
                      >
                        Delete <i className="bi bi-x-circle"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Dashboard" />

          <div className="row">
            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body">
                  {loading ? (
                    <Skeleton count={6} height="18px" />
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <h4 className="mb-0">Orders</h4>
                        </div>
                        <div className="icon-shape">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            width="18"
                            height="18"
                            fill="currentColor"
                          >
                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"></path>
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="fw-bold">
                          {formatThousands(
                            counters?.order ? counters?.order : 0,
                            { separator: "," }
                          )}
                        </h1>
                        <p className="mb-0">
                          <span>
                            {" "}
                            {formatThousands(
                              counters?.archiveOrder
                                ? counters?.archiveOrder
                                : 0,
                              { separator: "," }
                            )}
                          </span>{" "}
                          Archive Orders
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body">
                  {loading ? (
                    <Skeleton count={6} height="18px" />
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <h4 className="mb-0">Users Survey</h4>
                        </div>
                        <div className="icon-shape ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            width="18"
                            height="18"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z"
                            ></path>
                            <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z"></path>
                            <path
                              fillRule="evenodd"
                              d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="fw-bold">
                          {" "}
                          {formatThousands(
                            counters?.survey ? counters?.survey : 0,
                            { separator: "," }
                          )}
                        </h1>
                        <p className="mb-0">
                          <span>
                            {formatThousands(
                              counters?.archiveSurvey
                                ? counters?.archiveSurvey
                                : 0,
                              { separator: "," }
                            )}
                          </span>{" "}
                          Archive Survey
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div> */}
            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body">
                  {loading ? (
                    <Skeleton count={6} height="18px" />
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <h4 className="mb-0">Products</h4>
                        </div>
                        <div className="icon-shape ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-gift"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zM1 4v2h6V4H1zm8 0v2h6V4H9zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5V7zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5H7z" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="fw-bold">
                          {formatThousands(
                            counters?.gift ? counters?.gift : 0,
                            { separator: "," }
                          )}
                        </h1>
                        <p className="mb-0">
                          <span>
                            {formatThousands(
                              counters?.activeGift ? counters?.activeGift : 0,
                              { separator: "," }
                            )}
                          </span>{" "}
                          Active Products
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body">
                  {loading ? (
                    <Skeleton count={6} height="18px" />
                  ) : (
                    <>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <h4 className="mb-0">Voucher</h4>
                        </div>
                        <div className="icon-shape ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-badge-vo"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.508 11h1.429l1.99-5.999H6.61L5.277 9.708H5.22L3.875 5.001H2.5L4.508 11zM13.5 8.39v-.77c0-1.696-.962-2.733-2.566-2.733-1.604 0-2.571 1.029-2.571 2.734v.769c0 1.691.967 2.724 2.57 2.724 1.605 0 2.567-1.033 2.567-2.724zm-1.204-.778v.782c0 1.156-.571 1.732-1.362 1.732-.796 0-1.363-.576-1.363-1.732v-.782c0-1.156.567-1.736 1.363-1.736.79 0 1.362.58 1.362 1.736z" />
                            <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="fw-bold">
                          {formatThousands(
                            counters?.voucher ? counters?.voucher : 0,
                            { separator: "," }
                          )}
                        </h1>
                        <p className="mb-0">
                          <span>
                            {" "}
                            {formatThousands(
                              counters?.usedVoucher ? counters?.usedVoucher : 0,
                              { separator: "," }
                            )}
                          </span>{" "}
                          Used Voucher
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Latest Order</h4>
                </div>

                {tableLoader ? (
                  <div className="card-body">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <Table data={tableData} />
                )}

                <div className="bg-white text-center card-footer">
                  <Link className="link-primary" to="/admin/orders">
                    View All Orders
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
